// @ts-nocheck
import React, { PureComponent, Fragment } from 'react';
import { CSSTransition } from 'react-transition-group';
import Icon from '@components/Icon';
import { FullModal } from 'zzc-design-mobile';
import { jumpLink } from '@utils/url';
import './styles.scss';
import { EasyImage3 } from '@components/EasyImage';
import UserClickedRedPacket from '../../utils/user-click-redpacket';

const noCache = window.__initData.redPacket?.noCache;
if (noCache) {
    UserClickedRedPacket.remove();
}
class RedPacket extends PureComponent {
    showTransTimer = null;

    state = {
        showModal: true,
        showSmall: false,
        showTrans: false
    };

    async componentDidMount() {
        const { hasClickRedPacket } = await UserClickedRedPacket.get();

        this.setState({
            showTrans: true,
            showModal: !hasClickRedPacket,
            showSmall: hasClickRedPacket
        });
    }

    componentWillUnmount() {
        this.showTransTimer && clearTimeout(this.showTransTimer);
        this.showTransTimer = null;
    }

    handleGetRedPacket = () => {
        const {
            redPacket: {
                small: { target }
            }
        } = this.props;
        // const target = packet.target.replace(/index_banner/g, 'float_hongbao');
        if (target) {
            jumpLink({
                url: target
            });
        }
    };

    handleMModalGetRedPacket = () => {
        const {
            redPacket: { popup: { target } = {} }
        } = this.props;
        // const target = packet.target.replace(/index_banner/g, 'modal_hongbao');
        if (target) {
            if (!window.__initData.redPacket?.noCache) {
                UserClickedRedPacket.save({
                    hasClickRedPacket: true
                });
            }
            // 此处针对在app环境中
            this.setState({
                showTrans: false
            });
            setTimeout(() => {
                this.setState(
                    {
                        showModal: false,
                        showSmall: true
                    },
                    () => {
                        jumpLink({
                            url: target
                        });
                    }
                );
            }, 295);
        } else {
            this.hide();
        }
    };

    hide = () => {
        // 增加一个开关,如果没设置alwaysPopup一天只弹一次,如果设置了true,则每次刷新都弹窗(不会缓存是否弹窗过)
        if (!window.__initData.redPacket?.noCache) {
            UserClickedRedPacket.save({
                hasClickRedPacket: true
            });
        }

        this.setState({
            showTrans: false
        });
        setTimeout(() => {
            this.setState({
                showModal: false,
                showSmall: true
            });
        }, 295);
    };

    render() {
        const { showModal, showSmall, showTrans } = this.state;
        const {
            redPacket: {
                popup, small
                // small: { image: smallImage },
            }
        } = this.props;
        // 考虑到Popup弹窗可能不弹的情形，直接显示悬浮小红包
        const smallImage = small && small.image && small.image ? small.image : null;
        return (
            <Fragment>
                {((showSmall || !popup) && smallImage) && (
                    <div className="red-packet-container">
                        <div className="red-packet-wrapper">
                            <div
                                className="red-packet-content"
                                data-tj="领券_悬浮红包按钮_首页"
                                onClick={this.handleGetRedPacket}
                            >
                                <img className="red-packet-img" src={smallImage} />
                            </div>
                        </div>
                    </div>
                )}
                {popup && (
                    <FullModal
                        closeCallback={this.hide}
                        visible={showModal}
                        maskClose
                        style={{
                            zIndex: '999',
                            backgroundColor: 'rgba(0,0,0,0.7)'
                        }}
                        className="guide-popup-body"
                    >
                        <CSSTransition
                            in={showTrans}
                            timeout={300}
                            classNames="fade"
                            unmountOnExit
                        >
                            <div className="red-packet-animation">
                                <div
                                    className="model-red-packet"
                                    data-tj="领券_拆红包按钮_首页"
                                    onClick={this.handleMModalGetRedPacket}
                                >
                                    <EasyImage3
                                        className="model-red-packet"
                                        src={popup.image}
                                        opt={{
                                            fw: 578,
                                            quality: 90
                                        }}
                                    />
                                </div>
                                <div className="line" />
                                <div
                                    className="red-popup-close"
                                    data-tj="双十一领券_关闭按钮_首页"
                                    onClick={this.hide}
                                >
                                    <Icon name="Shut" />
                                </div>
                            </div>
                        </CSSTransition>
                    </FullModal>
                )}
            </Fragment>
        );
    }
}

export default RedPacket;
