// @ts-nocheck
import React, { Fragment, Component } from 'react';
import { Loading, Alert } from 'zzc-design-mobile';
import _ from 'lodash';
import { PageNormal as Page } from '@/components/Page';
import { NormalSearch, SearchBtnRef, SearchTabs } from '@/components/SearchContainer';
import * as zhimaService from '@service/zhima';
import * as cityInfoService from '@service/cityInfo';
import * as polymersService from '@service/polymers';
import * as homeService from '@service/home';
import { checkComments } from '@service/comment';
import { jumpLink, parseSearchParams } from '@utils/url';
import {
    handleToAccount,
    handleToOrder,
    isBlackApp,
    onPageShow,
    isApp,
    isCgbApp,
    isIos,
    isCrcApp,
    isPartner,
    getAppVersion,
    hasLogin,
    isAndroid,
    handleRemovePullToRefresh,
    pullToRefresh,
    handleShowNativeCalendar,
    isOneOfMiniprograms,
    isJdMiniprogram,
    miniNameSpace,
    isHuaweiQuickApp,
    listenerAppLoginStatus,
} from '@utils/native';
import { cleanSearchCache } from '@utils/clean-search';
import { getGeolocation } from '@utils/geolocation';
import { setLocationToStorage } from '@utils/handle-location';
import CitySelect from '@utils/user-city-select';
import { isEmptyObject, isNonEmptyObject, isObject, isUndefined } from '@utils/type';
import DateUitl from '@utils/date';
import * as logger from '@utils/logger';
import UserLastSelect from '@utils/user-last-select';
import SearchConditionSessionCache from '@utils/search-condition-session';
import { FormatSearchPickDate } from '@utils/format-search-date';
import getKeFuChatUrl from '@utils/getKeFuChatUrl';
// import CalendarContainer from '@components/CalendarContainer';
import { Calendar } from 'zzc-taro-h5';
import dayjs from 'dayjs';
import { sleep } from '@utils/noop';
import { isNeedUpdateSearchTime } from '@utils/update-search-time';
import {
    FormatSearchParams,
    initSearchParams,
    initActivitySearchParams,
} from './utils/format-search';
import getSafeAreaTop from './utils/get-safe-area-top';
import Banner from './components/Banner';
import HomeNavBar from './components/NavBar';
import BottomBar from './components/BottomBar';
import RedPacket from './components/RedPacket';

// 新版首页 ↓
import ServeContainer from './components/ServeContainer';
import Discount from './components/Discount';
import RelievedAndFastTravel from './components/RelievedAndFastTravel';
import LimitedTimeActivity from './components/LimitedTimeActivity';
import ScollToTopBar from './components/ScollToTopBar';
import Join from './components/Join';
// import GroupActivity from './components/GroupActivity';
import PreferentialPackage from './components/PreferentialPackage';
import Comment from './components/Comment';
// 新版日历组件
import './styles.scss';
import BackBtn from './components/BackBtn';
import Cheats from '@common/Cheats';
import OpenAppContainer from '@components/OpenAppContainer';
import EasyToast from '@components/EasyToast';
// import EasyImage from '@components/EasyImage';
import render from '../render';
import { replaceParams } from '@/utils/url';

import ZhimaUtils from '@/utils/zhima';
import WxUtils from '@/utils/wxAuth';
import LoginTip from './components/LoginTip';
import loginTipCoupon from './utils/loginTipCoupon';
import { deleteCookie, removeCookie } from '@utils/cookie';
import { vehicleSearch } from '@utils/za';

const { searchCalendarConfig } = window.__initData;
const SEARCH_CALENDAR_CONFIG = searchCalendarConfig || {};
const i18n = {
    pickerUpTimeLabel: '取',
    dropOffTimeLabel: '还'
}
class Home extends Component {
    constructor(props) {
        super(props);
        // 小程序太多入口跳回h5首页容易出问题，判断到处于小程序环境则切换回小程序的首页
        this.navBackMiniHome();
        const {
            userSelectlocalData,
            __initData: {
                redPacket,
                promotions = [],
                banners = [],
                monthlyCard = {},
                commonData,
                monthCardImg,
                joinBaner,
                iconTabs = [],
                innerTabs = [],
                easyTabs = [],
                preAuth = {},
                checkbox = {},
            },
        } = window;

        // 若有本地筛选数据记录，使用本地筛选数据
        const { searchFilter, oPlace, oplace } = initSearchParams({
            userSelectlocalData,
        });

        // 赛博坦活动页面的组件会带上特定的搜索条件跳转h5首页
        const {
            searchFilter: activitySearchFilter,
            oPlace: activityOPlace,
        } = this.parseActivitySearchFilter();
        this.state = {
            monthCardImg,
            banners,
            redPacket,
            promotions,
            monthlyCard,
            searchFilter: {
                ...searchFilter,
                ...activitySearchFilter,
            },
            guideList: [],
            oPlace: activityOPlace ?? oplace ?? oPlace,
            checkList: [],
            hasGeolocation: false,
            hasGetPackage: true,
            showCalendar: false,
            isShowScrollToTopBar: false,
            hideHomePop: commonData.hideHomePop,
            joinBaner,
            commentList: [],
            iconTabs,
            innerTabs,
            easyTabs,
            selectZhima: false,
            zhimaAuthCode: preAuth.zhima?.requestNo,
            zmChecked: checkbox?.hasPreAuth,
            showLoading: false,
            checkbox,
            isLogin: hasLogin(),
            maxDays: 180
        };
    }

    async componentDidMount() {
        this.fetchCommentList();
        this.fetchInitData();
        this.fetchCanlendarHoliday()
        if (isCrcApp()) {
            loginTipCoupon.checkCache();
            listenerAppLoginStatus(res => {
                if (!res) return;
                const type = Number(res.type);
                console.log('触发了');
                if (type === 0) {
                    deleteCookie('cdb_uid', '/');
                }
                this.setState({ isLogin: Number(res.type) === 1 });
            });
            // onPageShow(() => {
            //     this.setState({
            //         // eslint-disable-next-line react/no-unused-state
            //         isLogin: hasLogin()
            //     });
            // });
        }
        /* ===== 需要按顺序执行 ===== */
        await this.initSearchCondition();
        await this.updateSearchCondition();
        /* ===== 需要按顺序执行 ===== */
        onPageShow(this.updateSearchCondition);
        // 登陆回来更新芝麻免押状态
        onPageShow(this.handleUpdateState);

        if (SearchBtnRef && SearchBtnRef.current) {
            this.bindScollEventForSearchFrame();
        }
    }

    componentWillUnmount() {
        if (SearchBtnRef && SearchBtnRef.current) {
            document.removeEventListener('deviceready', this.devicereadyCallback);
            this.unbindScollEventForSearchFrame();
        }
    }

    onScroll = (() => {
        let tag = true;
        const timeoutTask = callback => () => {
            callback.call(this);
            tag = true;
        };

        // 节流方法
        const throttleMethod = window.requestAnimationFrame
            ? callback => window.requestAnimationFrame(timeoutTask(callback))
            : callback => window.setTimeout(timeoutTask(callback), 16.67);

        // 要监听的距离
        const distance = getSafeAreaTop();

        const updateDisplayState = () => {
            if (SearchBtnRef.current) {
                const top = SearchBtnRef.current.getBoundingClientRect().top;

                this.setState(prevState => {
                    const { isShowScrollToTopBar } = prevState;

                    if (top <= distance) {
                        if (!isShowScrollToTopBar) {
                            return {
                                isShowScrollToTopBar: true,
                            };
                        }
                    } else if (isShowScrollToTopBar) {
                        return {
                            isShowScrollToTopBar: false,
                        };
                    }
                    return null;
                });
            }
        };

        const callback = () => throttleMethod(updateDisplayState);

        return () => {
            if (tag) {
                tag = false;
                callback();
            }
        };
    })();

    bindScollEventForSearchFrame() {
        document.addEventListener('scroll', this.onScroll);
    }

    unbindScollEventForSearchFrame() {
        document.removeEventListener('scroll', this.onScroll);
        if (this._scrollTimeoutId) {
            window.clearTimeout(this._scrollTimeoutId);
        }
    }

    fetchCommentList = async () => {
        try {
            const { data } = await checkComments({
                recommend: 1, // 是否首页推荐，0不限制，1推荐
                pageSize: 5,
            });
            this.setState({
                commentList: data.list,
            });
        } catch (err) {
            console.error(err);
        }
    };

    /**
     * 更新搜索条件
     */
    updateSearchCondition = async () => {
        // isEmptyObject 对undefined也为false, 导致逻辑错误,所以这里加个默认值,
        const { pick = {}, drop = {} } = await CitySelect.get();
        if (drop && !isEmptyObject(drop)) {
            this.setState(
                {
                    searchFilter: {
                        pickup: {
                            ...pick,
                        },
                        dropoff: {
                            ...drop,
                        },
                        useDefault: false,
                    },
                    oPlace: true,
                    isShowSearch: true,
                },
                async () => {
                    CitySelect.remove();
                }
            );
        } else if (isEmptyObject(drop) && !isEmptyObject(pick)) {
            this.setState(
                {
                    searchFilter: {
                        pickup: {
                            ...pick,
                        },
                        dropoff: {
                            ...pick,
                        },
                        useDefault: false,
                    },
                    oPlace: false,
                    isShowSearch: true,
                },
                async () => {
                    CitySelect.remove();
                }
            );
        } else {
            const { searchFilter, oPlace } = await SearchConditionSessionCache.get();
            if (
                searchFilter &&
                !isEmptyObject(searchFilter) &&
                !this.isShowNativeCalendar
            ) {
                this.setState(
                    {
                        searchFilter,
                        oPlace,
                        isShowSearch: true,
                    },
                    () => {
                        SearchConditionSessionCache.remove();
                    }
                );
            } else {
                if (isApp() && !isCgbApp()) {
                    if (this.isShowNativeCalendar) {
                        this.isShowNativeCalendar = false;
                    } else {
                        UserLastSelect.get().then(userSelectlocalData => {
                            const { searchFilter, oPlace } = initSearchParams({
                                userSelectlocalData,
                            });
                            this.setState(prevState => ({
                                searchFilter: {
                                    ...prevState.searchFilter,
                                    ...searchFilter,
                                },
                                oPlace:
                                    oPlace === prevState.oPlace
                                        ? prevState.oPlace
                                        : oPlace,
                            }));
                        });
                    }
                }
            }
        }
    };

    initSearchCondition = async () => {
        if (isHuaweiQuickApp()) {
            return;
        }

        // 首次进入页面或者刷新页面，则从定位中获取数据
        if (isApp() && !isCgbApp()) {
            // 对于一进入页面就调用cordova的场景 最好是监听deviceready完成后再调用
            // 否则可能回因为版本或者各类异步问题无法调用成功
            if (this.isShowNativeCalendar) {
                this.isShowNativeCalendar = false;
            } else {
                document.addEventListener('deviceready', this.devicereadyCallback);
            }
        } else {
            await this.fetchGeolocation();
        }
    };

    devicereadyCallback = async () => {
        await this.fetchGeolocation();
    };

    // 经纬度换取城市信息
    fetchGeolocation = async () => {
        const userSelectlocalData = await UserLastSelect.get();
        // 如果没有搜索记录，并且未曾定位过，且为默认(也就是首次进入页面并且未点选任何地标)，执行定位，否则不执行
        if (isEmptyObject(userSelectlocalData) && this.state.searchFilter.useDefault) {
            const reg = /.+?(省|市|自治区|自治州|县|区|路|街道|道|路)/g;
            const location = await getGeolocation();
            if (!isEmptyObject(location)) {
                setLocationToStorage(location);
            }
            try {
                const { data: cityInfo } = await cityInfoService.locateCity(location);
                const { cityName, address, wCityId, poiFirst } = cityInfo;
                const index = cityName.indexOf('市');
                const result = index !== -1 ? cityName.slice(0, index) : cityName;
                const arr = address.match(reg);
                const address2 = address.indexOf('市');
                const result2 = address.slice(address2 + 1);
                const landmarkName =
                    arr && arr.length > 3 ? `${arr[2]}${arr[3]}` : result2;
                this.setState(prevState => ({
                    searchFilter: {
                        pickup: {
                            ...prevState.searchFilter.pickup,
                            ...cityInfo,
                            cityName: result,
                            landmarkName: poiFirst || landmarkName,
                            wCityId,
                        },

                        dropoff: {
                            ...prevState.searchFilter.dropoff,
                            ...cityInfo,
                            cityName: result,
                            landmarkName: poiFirst || landmarkName,
                            wCityId,
                        },
                        useDefault: false,
                    },
                }));
            } catch (error) {
                logger.error(error);
            }
        }
    };

    async fetchInitData() {
        if (hasLogin() && this.state.redPacket) {
            const { data } = await polymersService.requestUserPackage();
            this.setState({
                hasGetPackage: data.length > 0,
            });
        } else {
            this.setState({
                hasGetPackage: false,
            });
        }
    }

    handleSwitchSelectCity = (type, e) => {
        e.stopPropagation();
        const url = '/city';
        this.handleSaveArea({ type, url });
    };

    handleSwitchSelectArea = (type, e) => {
        e.stopPropagation();
        this.handleSaveArea({ type, url: '/city/land' });
    };

    handleSaveArea = ({ type, url }) => {
        const { pickup, dropoff } = this.state.searchFilter;
        const { oPlace } = this.state;
        const selectCity = {};
        selectCity.pick = {
            ...pickup,
            dropTime: dropoff.dropTime,
        };
        if (oPlace) {
            selectCity.drop = {
                ...dropoff,
            };
        }
        CitySelect.save(selectCity);
        const params = {
            type,
            from: 'home',
            oplace: oPlace || false,
        };
        if (url === '/city/land') {
            if (type === 'drop') {
                params.cityCode = dropoff.cityCode;
                params.cityName = dropoff.cityName;
            } else {
                params.cityCode = pickup.cityCode;
                params.cityName = pickup.cityName;
            }
        }
        SearchConditionSessionCache.save({
            oPlace,
            searchFilter: {
                ...this.state.searchFilter,
            },
        });
        jumpLink({
            url,
            params,
        });
    };

    handleSearch = () => {
        const {
            searchFilter: { pickup, dropoff },
            oPlace,
            checkList,
            selectZhima,
            checkbox,
        } = this.state;

        const urlParams = {
            pickup,
            dropoff,
            oPlace,
            checkList,
        };
        if (!isEmptyObject(checkbox)) {
            const extraParam = checkbox.extraParam.split('=');
            selectZhima &&
                checkbox?.checkbox &&
                (urlParams[extraParam[0]] = extraParam[1]);
        }
        const { params, userSelect } = FormatSearchParams(urlParams);
        if (isBlackApp()) {
            params.ZZCAppArg = 'webviewstart_statusbar,hideTopBar=true';
        }
        if (
            pickup.cityName === pickup.landmarkName ||
            (pickup.landmarkName != dropoff.landmarkName &&
                dropoff.cityName === dropoff.landmarkName)
        ) {
            EasyToast.warning('取还车地标错误，请重新选择取还车城市地标', 3);
            return;
        }
        // 有效的取车时间 当前时间往后四小时
        const isLegalTime =
            DateUitl.isAfter({
                start: DateUitl.today('YYYY/MM/DD HH:mm'),
                end: DateUitl.format(params.ptime, 'YYYY/MM/DD HH:mm'),
            }) &&
            DateUitl.isAfter({
                start: DateUitl.format(params.ptime, 'YYYY/MM/DD HH:mm'),
                end: DateUitl.format(params.dtime, 'YYYY/MM/DD HH:mm'),
            });
        if (isLegalTime) {
            if (params.pccode && params.plid) {
                this.handleSwitchSearch(params, userSelect);
            } else {
                EasyToast.warning('请选择取还车城市');
            }
        } else {
            this.setState(
                prevState => {
                    const defaultDate = new FormatSearchPickDate();
                    const newDate = defaultDate.getFormatTime();
                    return {
                        searchFilter: {
                            pickup: {
                                ...prevState.searchFilter.pickup,
                                pickTime: newDate.pickTime,
                            },
                            dropoff: {
                                ...prevState.searchFilter.dropoff,
                                dropTime: newDate.dropTime,
                            },
                        },
                    };
                },
                async () => {
                    const {
                        searchFilter: { pickup, dropoff },
                        oPlace,
                        checkList,
                        selectZhima,
                    } = this.state;
                    const urlParams = {
                        pickup,
                        dropoff,
                        oPlace,
                        checkList,
                    };
                    if (!isEmptyObject(checkbox)) {
                        const extraParam = checkbox.extraParam.split('=');
                        selectZhima &&
                            checkbox?.checkbox &&
                            (urlParams[extraParam[0]] = extraParam[1]);
                    }
                    const { params, userSelect } = FormatSearchParams(urlParams);
                    if (isBlackApp()) {
                        params.ZZCAppArg = 'webviewstart_statusbar,hideTopBar=true';
                    }
                    EasyToast.warning('取车时间过期，已为您重新匹配取车时间', 3);
                    await sleep(2000);
                    this.handleSwitchSearch(params, userSelect);
                }
            );
        }
    };

    handleSwitchSearch = (params, userSelect) => {
        cleanSearchCache(userSelect);
        UserLastSelect.save(userSelect);
        SearchConditionSessionCache.remove();
        // 点击搜索清空活动带上的搜索条件
        replaceParams({
            searchFilter: 'undefined',
        });

        vehicleSearch(params);

        jumpLink({
            url: '/search',
            params,
        });
    };

    handleOnCheck = checkList => {
        this.setState({
            checkList,
        });
    };

    // 是否异地
    handleSetOtherPlace = () => {
        this.setState(prevState => ({
            oPlace: !prevState.oPlace,
        }));
    };

    handleToClient = () => {
        jumpLink({
            url: getKeFuChatUrl(),
        });
    };

    /* ========= 新版日历交互start ========= */
    handleToggleCalendar = async () => {
        const { showCalendar } = this.state;
        if (showCalendar) {
            this.hideCalendar();
        } else {
            const {
                searchFilter: {
                    pickup: { pickTime },
                    dropoff: { dropTime },
                },
            } = this.state;
            // 用原生日历插件
            let version = isCrcApp() && (await getAppVersion());
            if (version) {
                version = version.replace('.', '');
            }
            if (version && parseFloat(version) >= 10.1) {
                // 标志，为了不执行devicereadyCallback
                this.isShowNativeCalendar = true;
                handleShowNativeCalendar(
                    {
                        // maxTimestamp: DateUitl.ToTimeStamp(DateUitl.addTime(90, 'day')),
                        pickUpTimestamp: DateUitl.ToTimeStamp(pickTime),
                        dropOffTimestamp: DateUitl.ToTimeStamp(dropTime),
                        landmarkPrompt: '',
                        minTimestamp: SEARCH_CALENDAR_CONFIG.minTimestamp,
                        maxTimestamp: SEARCH_CALENDAR_CONFIG.maxTimestamp,
                        pickUpMaxSelectedSpace: Number(
                            SEARCH_CALENDAR_CONFIG.pickUpMaxSelectedSpace
                        ),
                        pickUpAndDropOffMaxDays: Number(
                            SEARCH_CALENDAR_CONFIG.pickUpAndDropOffMaxDays
                        ),
                        pickUpAndDropOffMinHours: Number(
                            SEARCH_CALENDAR_CONFIG.pickUpAndDropOffMinHours
                        ), // 取还车之间最小间隔，小时（取还车最小间隔多少小时，比如，现在是1小时）
                        success: response => {
                            if (response.state === 0) {
                                const pickupTime = {};
                                const returnTime = {};
                                let data = response.data;
                                if (typeof data === 'string') {
                                    data = JSON.parse(data);
                                }

                                pickupTime.time = Number(data.pickUpTimestamp);
                                returnTime.time = Number(data.dropOffTimestamp);
                                this.handleConfirmCalendar( pickupTime, returnTime );
                            }
                        },
                    },
                    this.showCalendar
                );
                return;
            }
            // h5日历
            this.showCalendar();
        }
    };

    showCalendar = () => {
        this.setState(
            {
                showCalendar: true,
            },
            () => {
                if (isAndroid()) {
                    handleRemovePullToRefresh();
                }
            }
        );
    };

    hideCalendar = () => {
        this.setState(
            {
                showCalendar: false,
            }
            // () => {
            //     if (isAndroid()) {
            //         pullToRefresh(this.fetchInitData);
            //     }
            // }
        );
    };

    handleConfirmCalendar = (startTime, endTime) => {
        // const pickTime = DateUitl.format(opt.pickupTime.time, 'YYYY/MM/DD HH:mm');
        // const dropTime = DateUitl.format(opt.returnTime.time, 'YYYY/MM/DD HH:mm');
        // console.log('handleConfirmCalendar', pickTime);
        let pickTime = '', dropTime = ''
        if (dayjs.isDayjs(startTime)) {
            pickTime = startTime.format('YYYY/MM/DD HH:mm')
            dropTime = endTime.format('YYYY/MM/DD HH:mm')
        } else {
            pickTime = dayjs(startTime).format('YYYY/MM/DD HH:mm')
            dropTime = dayjs(endTime).format('YYYY/MM/DD HH:mm')
        }
        this.setState(
            prevState => ({
                searchFilter: {
                    pickup: {
                        ...prevState.searchFilter.pickup,
                        pickTime,
                    },
                    dropoff: {
                        ...prevState.searchFilter.dropoff,
                        dropTime
                    },
                },
            }),
            () => {
                this.hideCalendar();
            }
        );
    };

    /* ========= 新版日历交互end ========= */

    NavToMonthCard() {
        if (isPartner()) {
            jumpLink({
                url: '/mini/h5/month-card',
                noBook: true,
            });
        }
        else if (isOneOfMiniprograms()) {
            return miniNameSpace.navigateTo({
                url: '/pages/month-card/index',
            });
        }
        jumpLink({
            url: '/mini/h5/month-card',
            noBook: true,
        });
    }

    // 将活动页带上的筛选条件参数渲染到搜索框上
    parseActivitySearchFilter() {
        const { searchFilter: activitySearchFilterStr } = parseSearchParams();
        let activitySearchFilter;
        let activityOPlace;

        if (activitySearchFilterStr) {
            try {
                activitySearchFilter = JSON.parse(
                    decodeURIComponent(activitySearchFilterStr)
                );
                const { searchFilter, oPlace } = initActivitySearchParams(
                    activitySearchFilter
                );
                activitySearchFilter = searchFilter;
                activityOPlace = oPlace;
            } catch (error) {
                logger.error('传入首页的搜索条件异常', {
                    extra: {
                        error,
                        str: activitySearchFilterStr,
                    },
                });
            }
        }
        return {
            searchFilter: activitySearchFilter,
            oPlace: activityOPlace,
        };
    }

    // 返回小程序首页
    navBackMiniHome() {
        if (isPartner()) {
            jumpLink({
                url: '/mini/h5/month-card',
                noBook: true,
            });
        }
        if (isOneOfMiniprograms()) {
            // 京东的sdk加载稍慢，需要做延迟执行
            if (isJdMiniprogram()) {
                setTimeout(() => {
                    return miniNameSpace.switchTab({
                        url: '/pages/home/index',
                    })
                }, 600)
            }
            return miniNameSpace.switchTab({
                url: '/pages/home/index',
            });
        }
    }

    selectDeposit = e => {
        this.setState({
            selectZhima: !e,
        });
    };

    // 授权方法
    handleOnAuth = (type, isNoLoading = false) => {
        this.setState({
            showLoading: !isNoLoading,
        });
        const commonCallBack = (params = {}) => {
            this.setState({
                ...params,
                showLoading: false,
            });
            // 刷新文案
            this.handleUpdateState();
        };
        switch (type) {
            case 'zhima':
                const zhimaParams = {
                    authPoint: 'before_pay',
                };
                ZhimaUtils.authZhima(commonCallBack, zhimaParams);
                break;
            case 'wechatAuth':
                const wechatAuthParams = {
                    authPoint: 'before_pay',
                };
                WxUtils.authWx(commonCallBack, wechatAuthParams);
                break;
            default:
                EasyToast.error('未发现授权方式');
                break;
        }
    };

    // 解绑芝麻
    handleOnResetAuth = async () => {
        Alert({
            title: null,
            maskClose: true,
            content:
                '取消芝麻信用免押金授权后，将无法享受免押金租车。如需使用，请重新授权申请。',
            buttons: [
                {
                    text: '取消授权',
                    onPress: _.throttle(async () => {
                        this.setState({
                            showLoading: true,
                        });
                        zhimaService
                            .zhimaUnfreeze({
                                method: 1,
                            })
                            .then(
                                async res => {
                                    const result = res.data.result;
                                    if (result) {
                                        await this.handleUpdateState();
                                        this.setState(
                                            {
                                                zhimaAuthCode: '',
                                                showLoading: false,
                                            },
                                            () => {
                                                EasyToast.success('操作成功!');
                                            }
                                        );
                                    }
                                },
                                rej => {
                                    this.handleUpdateState();
                                    Alert({
                                        title: null,
                                        maskClose: true,
                                        content: rej.message,
                                        closeCallback: () => {
                                            this.setState({
                                                showLoading: false,
                                            });
                                        },
                                        buttons: [
                                            {
                                                text: '我知道了',
                                                onPress: () => {
                                                    this.setState({
                                                        showLoading: false,
                                                    });
                                                },
                                                props: {
                                                    type: 'special',
                                                    style: {
                                                        color: '#2871F7',
                                                        border: 'none',
                                                    },
                                                },
                                            },
                                        ],
                                    });
                                }
                            );
                    }, 6000),
                    props: {
                        type: 'special',
                        style: { color: 'rgb(105, 110, 124)', border: 'none' },
                    },
                },
                {
                    text: '保留授权',
                    onPress: () => {
                        this.setState({
                            showLoading: false,
                        });
                    },
                    props: {
                        type: 'special',
                        style: { color: '#2871F7', border: 'none' },
                    },
                },
            ],
        });
    };

    //
    handleUpdateState = async () => {
        const {
            data: { preAuth: checkbox },
        } = await homeService.getDepositCheckBox();
        this.setState({
            zmChecked: checkbox.hasPreAuth,
            checkbox,
        });
    };

    fetchCanlendarHoliday = async () => {
        const startTime = dayjs().valueOf()
        const endTime = dayjs().add(this.state?.maxDays, 'day')?.valueOf()
        const res = await homeService.getCalendarHoliday({ startTime, endTime })
        if (isObject(res) && isNonEmptyObject(res.data)) {
            this.setState({ calendarHoliday: res.data })
        } else {
            this.setState({ calendarHoliday: null })
        }
    }

    handleDropOffTimeExtraText = (startTime, endTime, options) => {
        const { now, crc_days, crc_hours, zzc_days } = options
        return ` (共${crc_days > 0 ? crc_days + '天' : ''}${crc_hours > 0 ? crc_hours + '小时' : ''})`
    }

    handleTips = (startTime, endTime) => {
        if (startTime && endTime) {
            const formatStart = parseFloat(startTime.format('H.m'))
            const formatEnd = parseFloat(endTime.format('H.m'))
            if (
                (formatStart < 8 || formatStart > 21) ||
                (formatEnd < 8 || formatEnd > 21)
            )
            return '取还车时间在夜间或凌晨可能会被收取夜间服务费'
        }    
    }

    navigateToOrder() {
        handleToOrder();
    }

    render() {
        const {
            oPlace,
            hasGetPackage,
            guideList,
            promotions,
            searchFilter: { pickup, dropoff },

            searchFilter: {
                pickup: { pickTime },
                dropoff: { dropTime },
            },
            showCalendar,
            banners,
            redPacket,
            isShowScrollToTopBar,
            monthlyCard,
            hideHomePop,
            // monthCardImg,
            joinBaner,
            commentList,
            iconTabs,
            innerTabs,
            easyTabs,
            zhimaAuthCode,
            zmChecked,
            selectZhima,
            showLoading,
            checkbox,
            isLogin,
            maxDays,
            calendarHoliday,
        } = this.state;
        console.log('calendarHoliday', calendarHoliday)
        return (
            <Fragment>
                <Page
                    id="zzc-home"
                    setTopBar={false}
                    defaultNavBarProps={{
                        title: '境内租车',
                    }}
                    navBar={
                        <HomeNavBar
                            onAccount={handleToAccount}
                            onOrder={this.navigateToOrder}
                        />
                    }
                >
                    <Loading visible={showLoading} text="请稍候..." />
                    <OpenAppContainer />
                    <Banner list={banners} />
                    <div className="search-container">
                        <NormalSearch
                            tabs={<SearchTabs />}
                            pickup={pickup}
                            dropoff={dropoff}
                            oPlace={oPlace}
                            onCity={this.handleSwitchSelectCity}
                            onLandmark={this.handleSwitchSelectArea}
                            onSearch={this.handleSearch}
                            onSwitch={this.handleSetOtherPlace}
                            onPick={this.handleToggleCalendar}
                            onCheck={this.handleOnCheck}
                            zhimaAuthCode={zhimaAuthCode}
                            zmChecked={zmChecked}
                            onChange={this.selectDeposit}
                            selectZhima={selectZhima}
                            hasLogin={hasLogin()}
                            zhimaAuth={this.handleOnAuth}
                            resetAuthZhima={this.handleOnResetAuth}
                            onAccount={handleToAccount}
                            checkbox={checkbox}
                        />
                    </div>
                    <PreferentialPackage
                        iconTabs={iconTabs}
                        innerTabs={innerTabs}
                        monthlyCard={monthlyCard}
                    />
                    {/* 月卡入口 */}
                    {/* {monthlyCard.entranceOpen && (
                        <div
                            data-tj="月卡_点击首页banner"
                            className="home-month-card-container"
                            onClick={this.NavToMonthCard}
                        >
                            <EasyImage
                                className="month-card-img"
                                src={monthCardImg}
                                alt="月卡"
                                opt={{
                                    fw: 702,
                                    quality: 70
                                }}
                            />
                        </div>
                    )} */}
                    {/* 屏蔽拼团入口 */}
                    {/* {!isAliPayMiniprogram() && <GroupActivity />} */}
                    <RelievedAndFastTravel guideList={guideList} easyTabs={easyTabs} />
                    <Discount />
                    <Comment commentList={commentList} />
                    <LimitedTimeActivity promotions={promotions} />
                    <Join joinBaner={joinBaner} />
                    <ServeContainer />
                    {/* 新版日历 */}
                    <Calendar 
                        height={'90%'}
                        onClose={this.hideCalendar}
                        onConfirm={this.handleConfirmCalendar}
                        visible={showCalendar}
                        holidays={calendarHoliday}
                        maxDays={maxDays}
                        minuteStep={10}
                        startTime={dayjs(pickTime)}
                        endTime={dayjs(dropTime)}
                        handleDropOffTimeExtraText={this.handleDropOffTimeExtraText}
                        handleTips={this.handleTips}
                        i18n={i18n}
                    />
                    {/* <CalendarContainer
                        show={showCalendar}
                        onCloseEvent={this.hideCalendar}
                        onConfirmEvent={this.handleConfirmCalendar}
                        pickTime={pickTime}
                        dropTime={dropTime}
                    /> */}
                    {isShowScrollToTopBar ? (
                        <ScollToTopBar pickup={pickup} dropoff={dropoff} />
                    ) : null}
                    <BackBtn isShow={!isShowScrollToTopBar} />
                    <BottomBar
                        onClient={this.handleToClient}
                        onOrder={this.navigateToOrder}
                    />
                    {redPacket && !hasGetPackage && !hideHomePop && (
                        <RedPacket redPacket={redPacket} />
                    )}
                    {/* App，小程序 登陆提示 http://pms.int.zuzuche.info/browse/GNZC-6110 */}
                    {isCrcApp() && !isLogin && <LoginTip />}
                    <Cheats />
                </Page>
            </Fragment>
        );
    }
}

UserLastSelect.get().then(res => {
    window.userSelectlocalData = res;
    render(Home);
});
