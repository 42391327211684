// @ts-nocheck
import React, { PureComponent } from 'react';
import IpxContainer from '@/components/IpxContainer';
import Icon from '@components/Icon';
import { isApp, isCrcApp, isTantuCrcApp, isOneOfQuickApp } from '@utils/native';
import styles from './style.module.scss';

class BottomBar extends PureComponent {
    render() {
        const { onOrder, onClient } = this.props;
        return (isApp() && !isCrcApp() && !isTantuCrcApp()) || isOneOfQuickApp() ? (
            <IpxContainer>
                <div id="footer-bar" className={styles['footer-bar-container']}>
                    <div className={styles['footer-bar-item']} onClick={() => onClient()}>
                        <div className={styles['icon-box']}>
                            <Icon name="Consult" />
                        </div>
                        <div className={styles['text']}>
                            <span>咨询</span>
                        </div>
                    </div>
                    <div className={styles['footer-bar-item']} onClick={() => onOrder()}>
                        <div className={styles['icon-box']}>
                            <Icon name="Order" />
                        </div>
                        <div className={styles['text']}>
                            <span>订单</span>
                        </div>
                    </div>
                </div>
            </IpxContainer>
        ) : null;
    }
}

export default BottomBar;
