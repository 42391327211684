// @ts-nocheck
import React, { useRef, useState, useLayoutEffect } from "react";
import dayjs from 'dayjs';
import { navBack, isCrcApp, isZZCApp, isBlackApp } from '@/utils/native';
import getSafeAreaTop from '../../utils/get-safe-area-top';
import Icon from "@/components/Icon";
import './style.scss';

function onClick() {
    try {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    } catch (error) {
        window.scrollTo(0, 0);
    }
}

/**
 * 格式化时间
 * @param {string} timeStr - 时间字符串
 */
function formatTime(timeStr) {
    return dayjs(timeStr).format('MM-DD HH:mm');
}

const isNeedBackBtn = isZZCApp() || isBlackApp();
// .scroll-to-top-bar-wrapper 元素的上padding-top
// 不用ref获取元素的padding-top的原因：会闪烁
const SCROLL_TO_TOP_BAR_WRAPPER = 0.24; // wrapper的上padding

// 如果无头部：总padding = 安全距离+原有padding
// 有头部：总padding = 原有padding
const top = isCrcApp() // 目前只有Crcapp没有头部
    ? getSafeAreaTop(SCROLL_TO_TOP_BAR_WRAPPER)
    : 0;

function ScollToTopBar({
    pickup: {
        pickTime,
        landmarkId: pickupLandmarkId,
        landmarkName: pickupLandmarkName
    },
    dropoff: {
        dropTime,
        landmarkId: dropoffLandmarkId,
        landmarkName: dropoffLandmarkName
    }
}) {
    return (
        <div
            className="scroll-to-top-bar-wrapper"
            {...top && { style: { paddingTop: `${top}px` } }}
        >
            {
                isNeedBackBtn && (
                    <button
                        className="back-btn"
                        onClick={(e) => {
                            e.stopPropagation();
                            navBack();
                        }}
                    >
                        <Icon name="Back" />
                    </button>
                )
            }
            <div
                className="scroll-to-top-bar"
                onClick={onClick}
            >
                <div className="text">
                    <p className="place">
                        <span className="from">
                            {pickupLandmarkName}
                        </span>
                        {
                            pickupLandmarkId !== dropoffLandmarkId ? (
                                <>
                                    <span className="icon">
                                        <Icon name="To_right_arrow"></Icon>
                                    </span>
                                    <span className="to">
                                        {dropoffLandmarkName}
                                    </span>
                                </>
                            ) : null
                        }

                    </p>
                    <p className="time">{formatTime(pickTime)} 至 {formatTime(dropTime)}</p>
                </div>
                <button
                    className="button"
                >
                    <Icon name="To_top"></Icon>
                    <span className="text">回到搜索</span>
                </button>
            </div>
        </div >
    );
}

export default ScollToTopBar;
