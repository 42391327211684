import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { navBack, isZZCApp, isBlackApp } from '@/utils/native';
import Icon from '@/components/Icon';
import styles from './style.module.scss';

const isNeedBackBtn = isZZCApp() || isBlackApp();

export default function BackBtn(props) {
    const {
        isShow
    } = props;

    return isNeedBackBtn ? (
        <CSSTransition
            in={isShow}
            timeout={1000}
            classNames={'back-btn-ani'}
            unmountOnExit
        >
            <button
                className={styles['back-btn']}
                onClick={navBack}
            >
                <Icon name="Back" />
            </button>
        </CSSTransition>
    ) : null;
}
