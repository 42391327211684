import React from 'react';
import { jumpLink } from '@/utils/url';
import styles from './style.module.scss';

function jumpEasyRent() {
    window.location.href = 'https://sbt.zuzuche.com/sbt/zijia_easy';
}

function jumpPackage(index) {
    jumpLink({
        url: `/package/${index}`,
    });
}

function jumpLongRent() {
    window.location.href = 'https://sbt.zuzuche.com/sbt/zijia_616';
}

function handleToTarget(target) {
    window.location.href = target;
}

// https://zzccdn.zuzuche.com/irc-common/upload_static/22/43/0c22230ab28305d29ae099e94f305d7e.png
export default function (props) {
    const {
        iconTabs = [],
        innerTabs = [],
        monthlyCard = {}
    } = props;
    const bigBanner = innerTabs.length > 0 && innerTabs[innerTabs.findIndex(item => item.type === 'large')];
    return (
        <section>
            <div className={styles['m-preferential-package']}>
                <ul className={styles['m-icon-tab']}>
                    {
                        iconTabs.length > 0 && iconTabs.map((item, i) => (
                            <li className={styles['tab-item']} key={item.id} onClick={() => handleToTarget(item.target)}>
                                <img className={styles['u-img']} src={item.image} alt="图标" />
                                <p className={styles['u-desc']}>{item.text}</p>
                                {/* {
                                    i === 0 && <div className={styles['u-icon']}>NEW</div>
                                } */}
                                {/* {
                                    i === 1 && <div className={styles['u-icon']}>HOT</div>
                                } */}
                            </li>
                        ))
                    }
                </ul>
                <ul className={styles['m-tow-banner']}>
                    {
                        innerTabs.length > 0 && innerTabs.map((item) => {
                            return item.type === 'small' ? (
                                <li className={styles['tab-item']} key={item.id} onClick={() => handleToTarget(item.target)}>
                                    <img className={styles['u-img']} src={item.image} alt="图标" />
                                </li>
                            ) : null;
                        })
                    }
                </ul>
                {
                    bigBanner && monthlyCard.entranceOpen && (
                        <div className={styles['m-large-banner']} onClick={() => handleToTarget(bigBanner.target)}>
                            <img className={styles['u-img']} src={bigBanner.image} alt="图标" />
                        </div>
                    )
                }
            </div>
        </section>
    );
}
