// @ts-nocheck
import { isIos } from '@utils/native';
import memoizeOne from 'memoize-one';

// extraDistance: 单位rem
export default memoizeOne((extraDistance = 0) => {
    let FRINGE_SCREEN_SAFE_TOP = 0; // 刘海屏顶部安全距离
    try {
        if (isIos()) {
            // IOS有刘海屏的机型直接读取css变量，【顶部安全距离: @/style/reset中定义的变量】
            FRINGE_SCREEN_SAFE_TOP = +(window
                .getComputedStyle(document.documentElement)
                .getPropertyValue('--safe-area-inset-top')
                .match(/[\d\.]+/)[0]);
        } else if (window.zzcApp) {
            const {
                zzcApp: {
                    deviceSafeAreaInsetTop = 0
                },
                devicePixelRatio = 1,
            } = window;
            // 安卓app，读取app插入的值
            FRINGE_SCREEN_SAFE_TOP = parseFloat(deviceSafeAreaInsetTop) / devicePixelRatio;
        }
    } catch (error) {
        FRINGE_SCREEN_SAFE_TOP = 0;
    }
    // rem单位数值
    let HTML_FONT_SIZE = 0;
    try {
        HTML_FONT_SIZE = +(window
            .getComputedStyle(document.documentElement).fontSize
            .match(/^[\d\.]+/)[0]);
    } catch (error) {
        HTML_FONT_SIZE = 0;
    }
    return extraDistance * HTML_FONT_SIZE + FRINGE_SCREEN_SAFE_TOP;
});
