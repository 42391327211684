// @ts-nocheck
import React, { Component, Fragment } from "react";
import classnames from 'classnames';
import { jumpLink } from '@/utils/url';
import SectionContainer from '../SectionContainer';
import './style.scss';

class Discount extends Component {
    render() {
        const textList = [{
            title: '领券立减500',
            subtitle: <>千元礼包<br/>限时抢</>
        }, {
            title: '平台独家优惠',
            subtitle: <>比门店租车<br/>便宜</>
        }, {
            title: '下单再减5%',
            subtitle: <>邀请好友<br/>砍价返现</>
        }];

        return (
            <SectionContainer title={
                <><span className="s-emphasized">3</span>重叠加优惠 比门店便宜</>
            } cls="section-header-1">
                <ul className="discount-list">
                    {
                        textList.map(item =>
                            <li
                                key={item.title}
                                className="discount-item"
                                onClick={() => jumpLink({ url: '/introduce/discount' })}
                            >
                                <p className="discount-item-title">{item.title}</p>
                                <p className="discount-item-subtitle">{item.subtitle}</p>
                            </li>
                        )
                    }
                </ul>
            </SectionContainer>
        )
    }
}

export default Discount;
