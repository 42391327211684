
// @ts-nocheck
import Local from '@utils/local-storage';

/**
 * 缓存用户已点击拆（关闭）领取红包
 */
class UserClickRedPacket {
  constructor() {
    this.key = 'CRD_ZZC_USER_CLICK_RED_PACKET_NORMAL';
  }

  get() {
    return new Promise((resolve) => {
      const data = Local.get(this.key) || {};
      resolve(data);
    });
  }
  
  save(data) {
    this.get().then((res) => {
      Local.set(this.key, {
        ...res,
        ...data
      }, 60 * 24);
    });
  }

  remove() {
    Local.remove(this.key);
  }
}

const UserClickedRedPacket = new UserClickRedPacket();

export default UserClickedRedPacket;
