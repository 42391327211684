// @ts-nocheck
import React, { memo, useEffect, useRef } from 'react';

import Swiper from 'swiper';
import EasyImage from '@components/EasyImage';
import 'swiper/css';

import './styles.scss';

function Banner(props) {
    const swiperRef = useRef();
    const initSwiper = (config = {}) => {
        swiperRef.current = new Swiper('.J-banner-list', {
            ...config,
        });
    }
    
    useEffect(() => {
        if (swiperRef.current) {
            return;
        }
        if (props?.list) {
            if (props?.list.length > 1) {
                initSwiper({
                    autoplay: {
                        delay: 5000,
                    },
                    loop: true,
                    lazyLoading: false,
                    a11y: {
                        enabled: false,
                    },
                    pagination: {
                        el: '.my-pagination',
                    },
                });
            } else {
                initSwiper();
            }
        }
    }, [props?.list?.length]);
    return (
        <div className="home-banner-list">
            <div className="swiper-container J-banner-list">
                <ul className="swiper-wrapper activity-swiper-wrapper">
                    {props?.list?.length > 0 &&
                        props?.list?.map?.((item, i) => {
                            return (
                                <li
                                    key={`banner-${i}`}
                                    className="swiper-slide activity-swiper-item"
                                >
                                    <a href={item.target}>
                                        <EasyImage
                                            src={item.image}
                                            opt={{
                                                fw: 750,
                                                quality: 90
                                            }}
                                            alt={item.text}
                                        />
                                    </a>
                                </li>
                            );
                        })}
                </ul>
            </div>
            <div className="my-pagination" />
            {/* <div className="home-banner-mask" /> */}
        </div>
    );
}
Banner.defaultProps = {
    list: []
};
export default memo(Banner);