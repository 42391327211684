// @ts-nocheck
import React, { memo } from 'react';
import { jumpLink } from '@utils/url';
import SectionContainer from '../SectionContainer';
import GuidePopup from '../GuidePopup';
import './style.scss';

function navigateToZhima() {
    jumpLink({ url: '/mine/zhimaAuth', noBook: true });
}

function handleToTarget(target) {
    window.location.href = target;
}

function RelievedAndFastTravel({ guideList = [], easyTabs = [] }) {
    if (easyTabs.length <= 0) return null;
    return (
        <SectionContainer title="轻松租车 全面保障" cls="section-header-1">
            <div className="relieved-and-fast-travel">
                {
                    easyTabs.length > 0 && easyTabs.map(item => (
                        <div className="tab-item" key={item.id} onClick={() => handleToTarget(item.target)}>
                            <img className="u-img" src={item.image} alt="图标" />
                        </div>
                    ))
                }
                {/* <div className="col1">
                    <div className="sesame" onClick={navigateToZhima}>
                        <p className="title">芝麻信用押金双免</p>
                        <p className="subtitle">租车押金，违章押金全免！</p>
                    </div>
                </div>
                <div className="col2">
                    <div className="row1">
                        <div
                            className="new-user-guide"
                            onClick={() => GuidePopup(guideList)}
                        >
                            <p className="title">1分钟完成预订</p>
                            <p className="subtitle">带齐两证轻松租车</p>
                        </div>
                    </div>
                    <div className="row2">
                        <div className="insurance" onClick={navigateToIns}>
                            <p className="title">用车安心保障</p>
                            <p className="subtitle">无忧租车，极速理赔</p>
                        </div>
                    </div>
                </div> */}
            </div>
        </SectionContainer>
    );
}

export default memo(RelievedAndFastTravel);
