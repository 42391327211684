import React, { memo } from 'react';
import './style.scss';

const list = [
    {
        title: '支持全国',
        subtitle: '600多个城市',
    },
    {
        title: '7x24小时',
        subtitle: '客户服务',
    },
    {
        title: '11年租车平台',
        subtitle: '服务超3千万用户',
    },
    {
        title: '信用免押金',
        subtitle: '免租车&违章押金',
    },
    {
        title: '送车上门',
        subtitle: '上门取车',
    },
    {
        title: '享3重优惠',
        subtitle: '比门店便宜 ',
    },
];

function ServeContainer() {
    return (
        <div className="serve-container">
            <div className="serve-container-title">境内租车就选租租车</div>
            <div className="serve-container-body">
                <ul className="serve-list">
                    {list.map(item => (
                        <li key={item.title} className="serve-item">
                            <div className="cover"></div>
                            <p className="title">{item.title}</p>
                            <p className="subtitle">{item.subtitle}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default memo(ServeContainer);
