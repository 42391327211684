// @ts-nocheck
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isOneOfQuickApp } from '@utils/native';
import NavBar from '@components/NavBar/NavBar';
import Icon from '@components/Icon';
import wapLogo from '@/image/wap_logo.png';
import './styles.scss';
// const wapLogo = new URL('@/image/wap_logo.png', import.meta.url).href;
// 快应用不显示头部。
const hideNavBar = isOneOfQuickApp();

export default class HomeNavBar extends Component {
    static propTypes = {
        title: PropTypes.string,
    };

    static defaultProps = {
        title: document.title,
    };

    shouldComponentUpdate(nextProps) {
        const { title, pickup } = this.props;
        return title !== nextProps.title || pickup !== nextProps.pickup;
    }

    render() {
        const { title, onOrder, onAccount } = this.props;
        if (hideNavBar) {
            return null;
        }
        return (
            <NavBar
                className="component-nav-bar-home"
                left={<ComponentLeft />}
                right={<ComponentRight onOrder={onOrder} onAccount={onAccount} />}
            />
        );
    }
}

function ComponentLeft(props) {
    const { onClick } = props;
    return (
        <div
            role="button"
            className="component-nav-bar-default-back-btn"
            onClick={event => {
                if (onClick) {
                    onClick(event);
                }
            }}
        >
            <div className="logo-box">
                <img alt="租租车-国内租车" src={wapLogo} />
            </div>
        </div>
    );
}

ComponentRight.propTypes = {
    onClick: PropTypes.func,
};

function ComponentRight(props) {
    const { onOrder, onAccount } = props;
    return (
        <Fragment>
            <div
                role="button"
                className="home-nav-bar-btn"
                onClick={event => {
                    if (onAccount) {
                        onAccount(event);
                    }
                }}
            >
                <div className="nav-item-box">
                    <div className="icon-box">
                        <Icon name="Account" />
                    </div>
                    <div className="text">
                        <span>我的</span>
                    </div>
                </div>
            </div>
            <div
                role="button"
                className="home-nav-bar-btn"
                onClick={event => {
                    if (onOrder) {
                        onOrder(event);
                    }
                }}
            >
                <div className="nav-item-box">
                    <div className="icon-box">
                        <Icon name="Order" />
                    </div>
                    <div className="text">
                        <span>订单</span>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
