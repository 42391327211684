// @ts-nocheck
import React, { memo } from 'react';
import SectionContainer from '../SectionContainer';
import { jumpLink } from '@/utils/url';
import { EasyImage3 } from '@components/EasyImage';
import './style.scss';

function LimitedTimeActivity({ promotions = [] }) {
    return promotions.length ? (
        <SectionContainer title="限时优惠活动">
            <div className="limited-time-activity">
                <ul className="pic-list">
                    {promotions.map(item => (
                        <li
                            key={item.link}
                            className="pic-item"
                            onClick={() =>
                                jumpLink({
                                    url: item.link,
                                })
                            }
                        >
                            <EasyImage3
                                className="pic-item"
                                src={item.pic}
                                opt={{
                                    fw: 702,
                                    quality: 70
                                }}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </SectionContainer>
    ) : null;
}

export default memo(LimitedTimeActivity);
