// @ts-nocheck
import { handleLogin } from '@utils/native';
import React, { memo } from 'react';
import { mergeToUrl } from '@utils/url';
import styles from './styles.module.scss';
import loginTipCoupon from '../../utils/loginTipCoupon';

// eslint-disable-next-line no-unused-vars
function LoginTip(props) {
    const handleClick = () => {
        const url = mergeToUrl(`${window.location.href}`, {
            autoGrabcoupon: true
        });
        loginTipCoupon.setCache();
        handleLogin(url);
    };
    console.log(location.href);


    return (
        <div className={styles['login-tip-container']}>
            <span>登录租租车，查看更多优惠</span>
            <div className={styles.btn} onClick={handleClick}>
                立即登录
            </div>
        </div>
    );
}
export default memo(LoginTip);
