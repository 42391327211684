// @ts-nocheck
import React, { memo } from 'react';
import './style.scss';
import EasyImage from '@components/EasyImage';
import { jumpLink } from '@/utils/url';

function Join({ joinBaner }) {
    return (joinBaner?.image) ? (
        <section
            className="join"
            onClick={() =>
                jumpLink({
                    url: joinBaner?.target ?? '/'
                })
            }
        >
            <EasyImage
                className="img"
                src={joinBaner?.image}
                alt="租车加盟"
                opt={{
                    fw: 750,
                    quality: 70
                }}
            />
        </section>
    ) : null;
}

export default memo(Join);
