// @ts-nocheck
import { fetchCouponTopicPlay } from '@service/bargain';
import { getUserId } from '@utils/cookie';
import ls from '@utils/local-storage';
import { hasLogin } from '@utils/native';

const lsKey = 'autoGrabcoupon';

// http://pms.int.zuzuche.info/browse/GNZC-6110
// 登陆提示，因为国内租车App登陆后会刷新webview，导致后面监听登录流程失效，无法发送获取优惠券请求。
// 所以存入ls，来实现夸页面，状态保存， 抽出一个业务逻辑类，减少同业务逻辑代码分散
// n 分钟内有效，n分钟内如果重新刷新首页执行checkCache（未登录状态），会自动清除缓存。不请求
class LoginTipCoupon {
    setCache = () => {
        ls.set(lsKey, 1, 10);
    }

    delCache = () => {
        ls.remove(lsKey);
    }

    checkCache = () => {
        const val = ls.get(lsKey);
        if (val && val !== null) {
            this.getCoupon();
            this.delCache();
        }
    }

    getCoupon() {
        // 如果10分钟内重新进入页面，但是还未登录，不进行领取
        if (!hasLogin()) {
            console.log('LoginTipCoupon', '未登录状态，无法进行获取新人券操作');
            return;
        }
        fetchCouponTopicPlay({
            topicCode: 'login118',
            userId: getUserId()
        }).catch((err) => {
            console.log('%c Line:25 🍩 err', 'color:#ea7e5c', err);
        });
    }
}
export default new LoginTipCoupon();
