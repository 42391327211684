import React, { memo } from 'react';
import './style.scss';

function Discount({ title, children, cls }) {
    return (
        <div className="section-wrapper">
            <div className={`section-header ${cls ? cls : ''}`}>{title}</div>
            <div className="section-body">{children}</div>
        </div>
    );
}

export default memo(Discount);
